@import "../appStyle";

$square-length: calc(100vw / 2 - 26px);

.main {
    height: 100%;
    width: 100%;

    &.digital-wall-view {
        .main-container.view-page {
            min-height: 100%;
        }
    }
}

.main-container {
    height: fit-content;
    min-height: calc(100% - 32px);
    width: calc(100% - 32px);
    padding: 16px;
    display: flex;
    flex-direction: column;

    &.with-header {
        margin-top: 51px;
    }

    &.view-page {
        padding: 0;
        width: 100%;
    }

    .big-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .info-text {
        margin-bottom: 10px;
    }

    .terms-container {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        ul, ol {
            margin: 0;
        }

        a {
            color: $link-color;
        }
    }
}

.landing-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main-container {
        flex-grow: 1;
        min-height: 0;
        padding-bottom: 0;

        .small-text {
            margin: 20px 24px 20px;
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 20px;
        }

        .big-text {
            text-align: center;
            margin: 24px 0 20px;
        }
    }

    .styled-list {
        margin: 0;
    }

    .bottom-section {
        padding-bottom: 16px;

        .styled-button {
            height: inherit;
        }
    }
}

.landing-page {
    .event-cover-container {
        background-color: $disabled-color;
        height: 272px !important;
        object-fit: contain;

        @media(min-width: 800px) {
            height: 500px !important;
        }
    }
}

.app-header {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
    z-index: 100;

    .top-bar-container {
        width: 100%;
        display: flex;
        flex-direction: row;

        .top-bar-tab {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 0.5;
            height: 48px;

            &.current {
                font-weight: 500;
            }
        }
    }

    .dividing-line {
        height: 1px;
        background-color: $black;
    }
}

.landscape {
    .landing-page {
        .styled-button {
            position: inherit;
        }
    }

    .share-page {
        .image-gallery {
            justify-content: flex-start;
        }
    }
}

.full-width-btn {
    width: 100%;
}

.white-text {
    color: $white !important;
}

.logo-name {
    font-size: 40px;
    line-height: 144%;
    font-weight: 600;
}

.share-page {

    .link-underline {
        margin-top: -7px !important;
    }
}

.image-gallery {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0 32px;
}

.main-container {

    .bottom-section {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .variable-sentence {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 5px;
        }
    }
}

.media-box {
    width: $square-length;
    height: $square-length;
    max-width: 210px;
    max-height: 210px;
    position: relative;

    img {
        width: $square-length;
        height: $square-length;
        max-width: 210px;
        max-height: 210px;
        object-fit: cover;
    }

    .MuiIconButton-root {
        position: absolute;
        right: 0;
        bottom: 0;
        margin-bottom: 4px;
        margin-right: 4px;
        background-color: $base-red;
        border-radius: 4px;
        height: 32px;
        width: 32px;
        color: $white;

        &.copy-icon {
            padding: 0;
        }

        :hover {
            background-color: $base-red;
            border-radius: 4px;
        }

        svg {
            height: 30px;
            width: 30px;
        }
    }
}

.view-page {
    background-color: $bg-light-grey;

    .floating-share-btn {
        position: fixed;
        bottom: 20px;
        right: 10px;
        font-size: 16px;
        font-weight: 500;
        height: 42px;
        width: fit-content;
        padding: 9px 12px;
        border-radius: 30px;
        gap: 5px;
    }

    .digital-wall {
        width: calc(100% - 16px);
        display: flex;
        justify-content: space-between;
        gap: 8px;
        padding: 0 8px;
        margin-bottom: 63px;

        .digital-wall-column {
            flex: 1;
        }
    }

    .digital-wall-footer {
        position: fixed;
        bottom: 0;
        width: calc(100% - 48px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        padding: 0 24px;

        .qr-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 25px;

            .qr-code {
                width: 45px;
                height: 45px;
            }
        }

        .sponsor-url-container {
            height: 55px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 90%;
            }
        }
    }
}

.event-post {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    background-color: $white;
    margin-top: 8px;

    &.loading-card {
        display: flex;
        flex-direction: row;
        gap: 5px;
        justify-content: center;
        align-items: center;
    }

    .post-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .like-icon {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;
        }
    }

    .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $bg-light-grey;
    }

    .media-container {
        width: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            max-height: calc(100vh * 0.66);
            object-fit: contain;
        }

        div {
            &.rendered-media {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .social-media-links {
        background-color: $white;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding: 0 38px 7px;

        img {
            height: 50px;
            width: 50px;
        }
    }
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 32px;
}

.terms-and-conditions {

}

.faq {
    gap: 32px;

    .q-a-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}

.upload-media-modal {
    text-align: center;
    .styled-notification-btns {
        flex-direction: column;
    }
}