@import "shared-components/generic/typographyStyle";
@import "shared-components/generic/genericStyle";

$sidebar-width: 240px;
$header-height: 43px;

.app {
    height: 100vh;
    color: $black;
}

.app-container {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
}

a {
    text-decoration: none;
    color: inherit;
}

select:focus {
    outline: none;
}

.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-container {
    display: flex;
}

.error {
    color: $error-color;
}

.warning {
    color: $attention-color;
}

.approval {
    color: $approval-color;
}

.swipe-container {
    width: 100%;
}

.logo-name-container {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 20px;
    line-height: 144%;
    font-weight: 600;
    color: $base-red;

    img {
        height: 32px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.special-scroll {
    &::-webkit-scrollbar-track {
        background: $white;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background: var(--scrollbar-color, $base-red);
    }

    &::-webkit-scrollbar-thumb {
        width: 8px;
        background: var(--scrollbar-color, $base-red);
        border-radius: 31px;
    }
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}